import { createSlice } from '@reduxjs/toolkit';

interface Setting {
  colorWeek: boolean;
  // 是否显示头部
  navbar: boolean;
  // 是否头部高度
  navbarHeight: number;
  // 主题色
  themeColor: string;
  // 是否显示左侧菜单
  showMenu: boolean;
  // 左侧菜单宽度
  menuWidth: number;
  // 是否显示面包屑
  showBreadcrumb: boolean;
  // 面包屑高度
  breadcrumbHeight: number;
  // 是否显示标签
  showTab: boolean;
  // 是否折叠左侧菜单
  isCollapsed: boolean;
  // 主题(明亮/暗黑)
  theme: string;
}

export interface GlobalState {
  settings?: Setting;
  userInfo?: {
    name?: string;
    avatar?: string;
    job?: string;
    organization?: string;
    location?: string;
    email?: string;
    permissions: Record<string, string[]>;
  };
  userLoading?: boolean;
}

// 初始数据
const initialState: GlobalState = {
  // 默认配置
  settings: {
    colorWeek: false,
    navbar: true,
    navbarHeight: 60,
    menuWidth: 220,
    showBreadcrumb: true,
    breadcrumbHeight: 40,
    themeColor: '#165DFF',
    showMenu: true,
    isCollapsed: false,
    theme: 'dark',
    showTab: true,
  },
  // 用户信息
  userInfo: {
    permissions: {},
  },
};
// global 的slice
const globalSlice = createSlice({
  name: 'global',
  // 配置初始数据
  initialState,
  // reducers: 修改state数据的方法
  reducers: {
    // 修改主题
    updateSetting: (state, action) => {
      const { settings } = action.payload;
      return {
        ...state,
        settings,
      };
    },
    // 修改用户信息
    updateUserInfo: (state, action) => {
      const { userInfo = initialState.userInfo, userLoading } = action.payload;
      return {
        ...state,
        userLoading,
        userInfo,
      };
    },
  },
});

export const { updateSetting, updateUserInfo } = globalSlice.actions;

export default globalSlice.reducer;
