import React, { Suspense, lazy, useEffect } from 'react';
import { Navigate, Routes, Route, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import Home from '@/pages/intelligent_analysis/data_conversation';

// 自定义 Hook，用于获取 routes
function useRoutes() {
  return useSelector((state: RootState) => state.permission.routes);
}

// 动态加载组件
const loadable = (importFunc) => {
  // 懒加载月面
  const Component = lazy(importFunc);
  return (props) => (
    // 使用 Suspense 来处理异步组件的加载
    <Suspense fallback={<div>Loading...</div>}>
      {/* 加载组件 */}
      <Component {...props} />
    </Suspense>
  );
};
// 构建路由
const buildRoutes = (routes) => {
  // 递归构建路由
  return routes.map((item, index) => {
    const route = {
      // path
      path: item.key,
      // 确保 key 的唯一性
      key: `${item.key}-${index}`, 
      // 页面
      element: null,
      // 子路由
      children: [],
    };
    // 如果是detail页面，将 path 修改为 detail
    if (item.key.includes('detail')) {
      route.path = 'detail';
    }
    // 如果有子路由
    if (item.children && item.children.length > 0) {
      // 如果有子路由，递归构建子路由
      route.children = buildRoutes(item.children);
      // 如果是系统管理页面，添加 Outlet
      if (
        [
          '/assessment',
          '/collaboration',
          '/gathering',
          '/system',
          '/dashboard/my_page',
        ].includes(item.key)
      ) {
        // 顶级路由且有子路由，添加一个空路径的子路由重定向到第一个子路由
        const firstChildPath = item.children[0].key;
        route.children.unshift({
          path: '',
          key: `${item.key}-redirect`,
          element: <Navigate to={firstChildPath} replace />,
        });

        // 对于顶级路由，设置 element 为 Outlet
        route.element = <Outlet />;
      } else {
        // 对于二级路由，设置 element 并使用 Outlet 渲染子路由
        const Component = loadable(
          () => import(`@/pages${item.componentPath}/index`)
        );
        // 使用自定义组件来处理条件渲染
        route.element = (
          <ConditionalRenderComponent Component={Component} path={item.key} />
        );
      }
    } else {
      // 没有子路由，直接加载组件
      route.element = loadable(
        () => import(`@/pages${item.componentPath}/index`)
      )({});
    }
    return route;
  });
};

// 自定义组件来处理条件渲染
const ConditionalRenderComponent = ({ Component, path }) => {
  // 定义路径和当前路径的比较
  const location = useLocation();
  // 检查路径是否以当前路径开头
  const isChildActive = path && location.pathname.startsWith(`${path}/`);
  // 如果是三级路由，直接渲染 Outlet
  if (isChildActive) {
    // 如果当前路径是三级路由，只渲染 Outlet
    return <Outlet />;
  } else {
    // 否则，渲染二级路由的组件和 Outlet
    return (
      <React.Fragment>
        <Component />
        <Outlet />
      </React.Fragment>
    );
  }
};

// 路由守卫组件
const AuthRouter = () => {
  // 获取路由数据
  const routes = useRoutes();
  // 获取当前路径
  const location = useLocation();
  useEffect(() => {
    // 检查 token 是否存在
    const token = localStorage.getItem('token');
    // 如果没有 token 且不在登录页面，则跳转到登录页面
    if (
      (!token && !location.pathname.startsWith('/login')) ||
      location.pathname == '/'
    ) {
      // 如果没有 token 且不在登录页面，则重定向到登录页面
      window.location.href = '/login';
    }
  }, [location.pathname]);

  // 构建异步路由
  const asyncRoutes = buildRoutes(routes);
  // 静态路由
  const staticRoutes = [
    ...asyncRoutes.map((route, index) => ({
      ...route,
      key: `child-route-${index}`, // 确保子路由的 key 唯一
    })),
    // 添加首页路由
    {
      path: '/intelligent_analysis/data_conversation',
      element: <Home />,
      key: 'home',
    },
    // 添加 404 路由
    {
      path: '*',
      element: <Navigate to="/404" replace />,
      key: 'not-found',
    },
  ];
  // 渲染路由
  const renderRoutes = (routes) => {
    // 递归构建路由
    return routes.map((route) => {
      return (
        <Route
          key={route.key}
          path={route.path}
          // 使用 Fragment 包裹，确保每个路由的 key 都是唯一的
          element={
            <React.Fragment key={location.pathname}>
              {route.element}
            </React.Fragment>
          }
        >
          {/* 如果有子路由，添加一个空的子路由，用于重定向 */}
          {route.children && route.children.length > 0 && (
            <Route path="" element={<Outlet />}>
              {/* 递归渲染子路由 */}
              {renderRoutes(route.children)}
            </Route>
          )}
        </Route>
      );
    });
  };

  return <Routes>{renderRoutes(staticRoutes)}</Routes>;
};

export default AuthRouter;
