import '../dialogue.less';
import React from 'react';
import { useNavigate } from 'react-router';
import {  Tag, Button } from '@arco-design/web-react';
import IconWord from '@/assets/images/word.svg';
import HomeTextArea from './textArea';
interface Item {
  id: number;
  text: string;
}

interface Props {
  // 搜索历史标签列表
  tagList: string[];
  // 统计数据
  countList: number[];
  // 最新的文电list
  messageList: Item[];
  // 最新采集的list
  gatheringList: Item[];
  // 按钮状态
  loading: boolean;
  // 提交textArea的数据
  submit: (values: string) => void;
}

const Static = ({
  tagList,
  countList,
  messageList,
  gatheringList,
  loading,
  submit,
}: Props) => {
  /** -------------------  定义一些变量  -------------------- */
  // 定义路由
  const navigate = useNavigate();

  // 统计栏的数据
  const countItem = [
    { icon: <IconWord />, title: '文电数', unit: '个', count: countList[0] },
    {
      icon: <IconWord />,
      title: '指标采集文档数',
      unit: '个',
      count: countList[1],
    },
  ];
  /** -------------------  定义一些函数  -------------------- */

  // 输入框按下回车，提交数据
  const handleSearch = (str?: string) => {
    submit(str);
  };

  //   前往具体模块页面
  const moveTo = (val: string) => {
    navigate(val);
  };

  return (
    <div className="height-100 dis-sb">
      {/* 左侧的数据 */}
      <div className="leftBox flex1 staticBox">
        {/* 顶部的两个标题 */}
        <p className="staticTitle">与数据对话，从这里开始</p>
        <p className="staticDescription">请输入您的问题，我会尽力为您解答</p>
        {/* input输入框 */}
        <HomeTextArea loading={loading} submit={handleSearch} />

        {/* 下方的tag */}
        <div>
          {tagList.map((item, index) => (
            <Tag
              bordered
              key={index}
              color={'var(--color-bg-2)'}
              onClick={() => handleSearch(item)}
              className="staticTagStyle"
            >
              {item}
            </Tag>
          ))}
        </div>
      </div>
      {/* 右侧的各种列表 */}
      <div className="rightBox">
        {/* 最上方的统计 */}
        <p className="listTitleStyle">数据统计</p>
        {/* 两个数据统计 */}
        <div className="dis-sb">
          {countItem.map((item) => (
            <div className="countItemStyle dis-left" key={item.title}>
              <div className="countItemIconStyle">{item.icon}</div>
              <div>
                <div className="countItemTitleStyle">{item.title}</div>
                <div>
                  <span className="countItemCountStyle">{item.count}</span>个
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* 最新文电 */}
        <div className="dis-sb">
          <div className="listTitleStyle">最新文电</div>
          <div
            className="listTitleStyle"
            style={{ fontSize: '14px' }}
            onClick={() => moveTo('/message')}
          >
            更多
          </div>
        </div>
        <div style={{ marginBottom: '20px' }}>
          {messageList.map((item) => (
            <div className="dis-sb border-bottom listItemStyle" key={item.id}>
              <span>{item.text}</span>
              <div>
                <Button type="text">查看</Button>
                <Button type="text">分析</Button>
              </div>
            </div>
          ))}
        </div>
        {/* 最新采集数据 */}
        <div className="dis-sb">
          <div className="listTitleStyle">最新采集</div>
          <div
            className="listTitleStyle"
            style={{ fontSize: '14px' }}
            onClick={() => moveTo('/message')}
          >
            更多
          </div>
        </div>
        <div>
          {gatheringList.map((item) => (
            <div className="dis-sb border-bottom listItemStyle" key={item.id}>
              <span>{item.text}</span>
              <div>
                <Button type="text">查看</Button>
                <Button type="text">分析</Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Static;
