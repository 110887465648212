// 后端接口的baseUrl

export const REACT_SERVER = process.env.REACT_APP_BASE_URL + '/api';

// 后端websocket的baseUrl
export const WebSocket_URL = process.env.REACT_APP_WEBSOCKET_URL;


// 后端返回的code成功的
export const RES_CODE = '0000';

// 后端返回的code成功的
export const RES_STATUS = 200;

import http from '@/utils/axios';
import type { AxiosResponse } from 'axios';



// 通用下载
export const commonDownload = async (fileId: number) => {
  const _res: AxiosResponse = await http.downloadGet(`/common/file/download/${fileId}`);
  return _res;
};


// 通用上传
export const commonUpload = async (params) => {
  const _res = await http.upload(`/common/file/upload`, params);
  return _res;
};