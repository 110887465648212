import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
 // 默认使用 localStorage
import storage from 'redux-persist/lib/storage';
// 引入 reducer
import globalReducer from './modules/global';
import tabsReducer from './modules/tabs';
import permissionReducer from './modules/permission';
import systemReducer from './modules/system'
import assessmentReducer from './modules/assessment'
// 创建 reducer
const rootReducer = combineReducers({
  // global 模块
  global: globalReducer,
  // tabs 模块
  tabs: tabsReducer,
  // permission 模块
  permission: permissionReducer,
  // system 模块
  system:systemReducer,
  // assessment 模块
  assessment:assessmentReducer
});

// 配置持久化
const persistConfig = {
  key: 'root',
  // 使用 localStorage 进行持久化
  storage, 
  // 白名单
  whitelist: ['global', 'tabs', 'permission','system','assessment'], // 持久化 global 和 tabs slice
};
// 持久化 reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// 创建 store
const store = configureStore({
  reducer: persistedReducer,
  // 关闭 redux-thunk 检查
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // 关闭序列化检查，因为持久化会涉及到非序列化的数据
    }),
});
// 创建 persistor
const persistor = persistStore(store);

// 推断 `RootState` 和 `AppDispatch` 类型
export type RootState = ReturnType<typeof store.getState>;
// 推断 `dispatch` 类型
export type AppDispatch = typeof store.dispatch;

export { store, persistor };
