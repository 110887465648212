// LayoutHeader.tsx
import React, { useContext, useRef } from 'react';
import { GlobalContext } from '@/context';
import LayoutStyles from '@/style/layout.module.less';
import './header.less';
import Logo from '@/assets/logo.svg';
import IconButton from '../component/IconButton/IconButton';
import Settings from '../component/Settings/index';
import { useNavigate } from 'react-router-dom';
import {
  Tooltip,
  Layout,
  Dropdown,
  Menu,
  Divider,
  Message,
} from '@arco-design/web-react';
import {
  IconSunFill,
  IconMoonFill,
  IconSettings,
  IconPoweroff,
  IconUser,
  IconLarkColor,
  // IconRefresh,
} from '@arco-design/web-react/icon';
import service from '@/api/login';
import ModifyPwd, { ModifyPwdRef } from './modifyPwd';
import { RES_CODE } from '@/api/service';

const Header = Layout.Header;

function LayoutHeader() {
  const { theme, setTheme } = useContext(GlobalContext);
  // 定义路由
  const navigate = useNavigate();
  // 下拉按钮的各种事件
  const changeUser = async (key: string) => {
    switch (key) {
      case 'logout': {
        await service.logout();
        navigate('/login');
        localStorage.clear();
        break;
      }
      case 'modifyPwd': {
        showModifyPwd();
        break;
      }
      default:
        break;
    }
  };

  // 定义修改密码的弹窗
  const modifyPwdRef = useRef<ModifyPwdRef>(null);

  // 弹出修改密码的弹窗
  const showModifyPwd = () => {
    modifyPwdRef.current.changeVisible();
  };

  // 确认修改密码
  const modifySubmit = async (value) => {
    const _params={
      ...value,
      id:JSON.parse(localStorage.getItem('user')).userId
    }
    const _res = await service.modifyPwd(_params);
    if (_res.code === RES_CODE) {
      Message.success('修改成功');
      modifyPwdRef.current.changeVisible();
    }
  };

  // 点击头像出现的下拉框
  const dropList = (
    <Menu>
      <Menu.Item key="1">
        <IconUser className={LayoutStyles['dropdown-icon']} />
        用户名称
      </Menu.Item>
      <Menu.Item key="modifyPwd" onClick={() => changeUser('modifyPwd')}>
        <IconSettings className={LayoutStyles['dropdown-icon']} />
        修改密码
      </Menu.Item>
      <Divider style={{ margin: '4px 0' }} />
      <Menu.Item key="loginOut" onClick={() => changeUser('logout')}>
        <IconPoweroff className={LayoutStyles['dropdown-icon']} />
        退出登录
      </Menu.Item>
    </Menu>
  );

  return (
    <Header>
      <div className="navbar">
        <div className={'left'}>
          <div className={'logo'}>
            <Logo />
            <div className={'logo-name'}>效能数据分析系统</div>
          </div>
        </div>
        <ul className={'right'}>
          <li>
            <Tooltip
              content={theme === 'light' ? '切换为暗黑主题' : '切换为亮色主题'}
            >
              <IconButton
                icon={theme !== 'dark' ? <IconMoonFill /> : <IconSunFill />}
                onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')}
              />
            </Tooltip>
          </li>
          {/* <li>
            <Tooltip content={'刷新平台数据'}>
              <IconButton
                icon={<IconRefresh />}
                onClick={() => getMenu()}
              />
            </Tooltip>
          </li> */}
          <Settings />
          <li>
            <Dropdown droplist={dropList} position="br">
              <IconLarkColor className={LayoutStyles['dropdown-icon']} />
            </Dropdown>
          </li>
        </ul>
        <ModifyPwd ref={modifyPwdRef} submit={modifySubmit} />
      </div>
    </Header>
  );
}

export default LayoutHeader;
