import {
  Form,
  Input,
  Checkbox,
  Button,
  Space,
} from '@arco-design/web-react';
import { FormInstance } from '@arco-design/web-react/es/Form';
import { IconLock, IconUser } from '@arco-design/web-react/icon';
import React, { useEffect, useRef, useState } from 'react';
import useStorage from '@/utils/useStorage';
import { useDispatch } from 'react-redux';
import styles from './style/index.module.less';
// 引入接口
import service from '@/api/login';
import { RES_CODE } from '@/api/service';
// 引入方法，更改路由参数
import { changeRouter, changeFlattenRoute } from '@/store/modules/permission';
import { getFlattenRoutes, flattenRoutes } from '@/utils';

import { useNavigate } from 'react-router-dom';

export default function LoginForm() {
  // 表单的ref
  const formRef = useRef<FormInstance>();
  // 按钮是否显示loading
  const [loading, setLoading] = useState(false);
  //
  const [loginParams, setLoginParams, removeLoginParams] =
    useStorage('loginParams');

  // store的调用
  const dispatch = useDispatch();
  // 路由
  const navigate = useNavigate();

  const [rememberPassword, setRememberPassword] = useState(!!loginParams);
  // 是否记住当前用户
  function afterLoginSuccess(params) {
    // 记住密码
    if (rememberPassword) {
      setLoginParams(JSON.stringify(params));
    } else {
      removeLoginParams();
    }
    // 跳转首页
    navigate('/intelligent_analysis/data_conversation');
  }
  // 获取路由菜单
  async function getMenu(params) {
    const _res = await service.getMenu();
    if (_res.code === RES_CODE) {
      const _list = getFlattenRoutes(_res.data.menus);
      // 将获取到的路由菜单存进redux
      dispatch(changeRouter(_list));
      // 将路由扁平化
      const _flattenList = flattenRoutes(_list);
      // 将路由扁平化存进redux
      dispatch(changeFlattenRoute(_flattenList));
      afterLoginSuccess(params);
    }
  }

  async function login(params) {
    setLoading(true);
    try {
      // 调用接口
      const _res = await service.login(params);
      // 如果登录成功
      if (_res.code == RES_CODE) {
        // 存储token
        localStorage.setItem('token', _res.data.token);
        // 存储当前用户信息
        localStorage.setItem('user', JSON.stringify(_res.data.userContext));
        getMenu(params);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  // 校验，完成后触发接口
  function onSubmitClick() {
    formRef.current.validate().then((values) => {
      login(values);
    });
  }

  // 读取 localStorage，设置初始值.这里主要是看前面是否勾选了记住我
  useEffect(() => {
    const rememberPassword = !!loginParams;
    // 设置初始值
    setRememberPassword(rememberPassword);
    if (formRef.current && rememberPassword) {
      const parseParams = JSON.parse(loginParams);
      formRef.current.setFieldsValue(parseParams);
    }
  }, [loginParams]);

  return (
    <div style={{ width: '320px' }}>
      <div className={styles['login-form-title']}>登录评估系统</div>
      {/* 表单 */}
      <Form
        className={'login-form'}
        layout="vertical"
        ref={formRef}
        initialValues={{ loginName: 'admin', password: 'admin123' }}
      >
        {/* 用户名 */}
        <Form.Item
          field="loginName"
          rules={[{ required: true, message: '请输入用户名' }]}
        >
          <Input
            prefix={<IconUser />}
            placeholder={'请输入用户名'}
            onPressEnter={onSubmitClick}
          />
        </Form.Item>
        <Form.Item
          field="password"
          rules={[{ required: true, message: '请输入密码' }]}
        >
          <Input.Password
            prefix={<IconLock />}
            placeholder={'请输入密码'}
            onPressEnter={onSubmitClick}
          />
        </Form.Item>
        <Space size={16} direction="vertical">
          <div>
            <Checkbox checked={rememberPassword} onChange={setRememberPassword}>
              记住密码
            </Checkbox>
          </div>
          <Button type="primary" long onClick={onSubmitClick} loading={loading}>
            登录
          </Button>
          {/* <Button
            type="text"
            long
            className={styles['login-form-register-btn']}
          >
            {t['login.form.register']}
          </Button> */}
        </Space>
      </Form>
    </div>
  );
}
