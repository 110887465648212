import { createSlice } from '@reduxjs/toolkit';
// 扁平化数组的类型
interface FlattenRoute {
  label: string;
  key: string;
}
// 路由的类型
interface IRouteItem {
  label: string;
  key: string;
  children?: IRouteItem[];
  icon?: JSX.Element;
  componentPath?: string;
}
// 路由的state的类型
interface PermissionState {
  routes: IRouteItem[];
  flattenRoute: FlattenRoute[];
}
// 初始化路由的state
const initialState: PermissionState = {
  routes: [],
  flattenRoute: [],
};
// 路由的redux
const permissionSlice = createSlice({
  name: 'permission',
  // 初始化路由的state
  initialState,
  reducers: {
    // 更改路由
    changeRouter: (state, action) => {
      // 替换掉原来的路由
      state.routes = action.payload as IRouteItem[];
    },
    // 更改扁平化路由
    changeFlattenRoute: (state, action) => {
      // 直接更新 state.routes
      state.flattenRoute = action.payload as FlattenRoute[];
    },
  },
});

export const { changeRouter,changeFlattenRoute } = permissionSlice.actions;

export default permissionSlice.reducer;
