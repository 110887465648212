// 改变主题
function changeTheme(theme) {
  // 如果是明亮模式
  if (theme === 'dark') {
    document.body.setAttribute('arco-theme', 'dark');
  } else {
    // 如果是暗黑模式
    document.body.removeAttribute('arco-theme');
  }
}
// 返回主题
export default changeTheme;
