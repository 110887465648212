import React, { useState } from 'react';
import {
  Input,
  Button,
  Upload,
  Space,
} from '@arco-design/web-react';
import {
  IconSend,
  IconRecordStop,
  IconAttachment,
} from '@arco-design/web-react/icon';
import '../dialogue.less';

interface Props {
  loading: boolean;
  submit: (values: string) => void;
}

const HomeInputArea = ({ loading, submit }: Props) => {
  const [inputValue, setInputValue] = useState('');
  const [fileList, setFileList] = useState([]);

  const handleTextChange = (e:string) => {
    setInputValue(e);
  };

  const handleSearch = () => {
    submit(inputValue);
    setInputValue(''); // Clear input value
    setFileList([]); // Clear file list
  };

  return (
    <div className="position-re textArea-box" style={{height:fileList.length ?'160px':'100px'}}>
      {/* Display uploaded files */}
      {/* {fileList.length > 0 && (
        <Upload action="" fileList={fileList} className={'top-upload-button'}></Upload>
      )} */}
      {/* Text input part */}
      <Input.TextArea
        placeholder="请输入您的问题"
        value={inputValue}
        onChange={(value)=>handleTextChange(value)}
        autoSize={{ minRows: 4, maxRows: 6 }}
        style={{
          backgroundColor: 'var(--color-bg-2)',
          borderRadius: '10px',
          maxHeight: '70px',
          overflow: 'auto',
          flex: 1,
        }}
        className={'textArea-input'}
      />

      {/* Buttons part */}
      <Space size="small" className={'textArea-button'}>
        <Upload
          limit={1}
          action=""
          showUploadList={false}
          drag
          accept=""
          fileList={fileList}
          onChange={(files) => setFileList(files)}
        >
          <Button
            icon={<IconAttachment />}
            style={{ marginRight: '10px' }}
          ></Button>
        </Upload>
        <Button
          type={loading ? 'secondary' : 'primary'}
          icon={loading ? <IconRecordStop /> : <IconSend />}
          onClick={handleSearch}
          className={loading ? '' : 'transform-button'}
        ></Button>
      </Space>
    </div>
  );
};

export default HomeInputArea;

