import React from 'react';
import './style/index.module.less';
// 引入图片
import login from '@/assets/images/banner.jpg'
export default function LoginBanner() {
  // 背景图片
  return (
      <div className='banner'>
        <img src={login} alt="登陆图片" />
      </div>
  );
}

