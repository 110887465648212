// index.tsx
import React, { useEffect, useState } from 'react';
import { Layout } from '@arco-design/web-react';
import LayoutAside from './layoutAside/aside';
import LayoutHeader from './layoutHeader';
import styles from '@/style/layout.module.less';
import { useLocation } from 'react-router-dom';
// import Tabs from '@/layout/component/Tabs';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import './layout.less'
// // 动态修改 CSS 变量
// function setTopOffset(value) {
//   document.documentElement.style.setProperty('--top-offset', value + 'px');
// }

function PageLayout() {
  const location = useLocation();
  // const [breadcrumb, setBreadCrumb] = useState([]);

  const [selectRouteName,setSelectRouteName] = useState('')
  // 获取被扁平化的路由
  const flattenRoute = useSelector(
    (state: RootState) => state.permission.flattenRoute
  );

  const settings = useSelector((state: RootState) => state.global.settings);
  // 根据路径获取面包屑
  useEffect(() => {
    // const _arr = flattenRoute.filter((item) =>
    //   location.pathname.includes(item.key)
    // );
    // setBreadCrumb(_arr);
    const _routeName = flattenRoute.find((item) => item.key === location.pathname).label;
    setSelectRouteName(_routeName)
  }, [location.pathname, flattenRoute]);

  //  面包屑的高度
  const breadHeight = settings.showBreadcrumb ? settings.breadcrumbHeight : 0;
  const _height = window.innerHeight - breadHeight - 60;

  const _width = window.innerWidth - (settings.isCollapsed ? 120 : 240);

  // 根据settings的一些值，设置样式
  // useEffect(() => {
  //   let topNum = 0;
  //   // 如果不显示面包屑
  //   if (settings.showBreadcrumb) {
  //     topNum += 40;
  //   }
  //   // 如果不显示导航栏
  //   if (settings.showTab) {
  //     topNum += 40;
  //   }
  //   // 如果不显示头部
  //   if (settings.navbar) {
  //     topNum += 60;
  //   }

  //   setTopOffset(topNum)

  // }, [settings.showBreadcrumb, settings.navbar, settings.showTab]);

  return (
    <Layout className={styles.layout}>
      <LayoutHeader />
      <div className="sectionStyle">
        <LayoutAside />
        <div className="flex1  mainStyle" style={{ width: _width }}>
          {/* <div style={{ height: tabsHeight }}>
            <Tabs />
          </div> */}
          {/* {!!breadcrumb.length && (
            <div
              style={{ height: breadHeight }}
              className={styles['layout-breadcrumb']}
            >
              <Breadcrumb>
                {breadcrumb.map((node, index) => (
                  <Breadcrumb.Item key={index}>{node.label}</Breadcrumb.Item>
                ))}
              </Breadcrumb>
            </div>
          )} */}
          <div className='selectRouteName'  style={{ height: breadHeight }}>{selectRouteName}</div>
          <div style={{ height: _height }}>
            <Outlet></Outlet>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default PageLayout;
