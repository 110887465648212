/**
 * @description 获取assets静态资源
 * @param url
 * @returns
 */
export const getAssets = (url: string) => {
  return new URL(`../assets/images/${url}`, import.meta.url).href;
};

export function getTime(inputTime: string): string {
  // 解析输入的时间字符串
  const date = new Date(inputTime);
  // 检查解析是否成功
  if (isNaN(date.getTime())) {
    throw new Error('Invalid date format');
  }

  // 将时间调整为北京时间（UTC+8）
  const beijingTime = new Date(date.getTime() + 8 * 60 * 60 * 1000);

  // 格式化时间为 "YYYY-MM-DD HH:mm:ss"
  const year = beijingTime.getUTCFullYear();
  const month = String(beijingTime.getUTCMonth() + 1).padStart(2, '0');
  const day = String(beijingTime.getUTCDate()).padStart(2, '0');
  const hours = String(beijingTime.getUTCHours()).padStart(2, '0');
  const minutes = String(beijingTime.getUTCMinutes()).padStart(2, '0');
  const seconds = String(beijingTime.getUTCSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

// 递归机构的数据,将数据转换为key，title(主要是tree使用)
export const formatData = (data) => {
  return data.map((item) => {
    const children =
      item.children && item.children.length > 0
        ? formatData(item.children)
        : undefined;

    return {
      label: item.orgName,
      value: item.orgId,
      type: item.type,
      ...(children !== undefined && { children }), // 只有在 children 不为空时才添加 children 属性
    };
  });
};



// 将数据转为扁平化数据
export function objectToFormData(obj, form, namespace = '') {
  for (const property in obj) {
    // debugger;
    if (obj.hasOwnProperty(property)) {
      const tempItem = obj[property];

      if (Array.isArray(tempItem)) {
        // 处理数组
        for (let index = 0; index < tempItem.length; index++) {
          // 使用for循环替代for...in以处理数组
          const formKey = namespace
            ? `${namespace}[${index}]`
            : `${property}[${index}]`;
          const indexItem = tempItem[index];

          if (typeof indexItem === 'object' && !(indexItem instanceof File)) {
            objectToFormData(indexItem, form, formKey); // 递归调用
          } else {
            form.append(formKey, indexItem);
          }
        }
      } else if (typeof tempItem === 'object' && !(tempItem instanceof File)) {
        // 处理非数组对象
        const key = namespace ? `${namespace}.${property}` : property;
        objectToFormData(tempItem, form, key); // 递归调用
      } else {
        // 处理字符串或File对象
        const formKey = namespace ? `${namespace}.${property}` : property;
        form.append(formKey, tempItem);
      }
    }
  }

  return form;
}
// 将json表单转化为表单数据
export function urlEncode(param, key = '', encode = true) {
  if (param == null) return '';
  // 定义一个空字符串
  let paramStr = '';
  // 判断参数类型
  const t = typeof param;

  // 处理基本类型
  if (t === 'string' || t === 'number' || t === 'boolean') {
    paramStr += '&' + key + '=' + (encode ? encodeURIComponent(param) : param);
  } else if (typeof param === 'object') {
    // 处理对象或数组
    for (const i in param) {
      // 遍历
      if (param.hasOwnProperty(i)) {
        const k = key
          ? key + (Array.isArray(param) ? '[' + i + ']' : '.' + i)
          : i;
        paramStr += urlEncode(param[i], k, encode);
      }
    }
  }
  return paramStr;
}

// 递归处理参数
export function urlDonloadEncode(param, key = '', encode = true) {
  if (param == null) return '';
  // 定义一个空字符串
  let paramStr = ''
  // 判断数据类型
  const t = typeof param;

  // 处理基本类型
  if (t === 'string' || t === 'number' || t === 'boolean') {
    paramStr += key + '=' + (encode ? encodeURIComponent(param) : param);
  } else if (t === 'object') {
    // 处理对象或数组
    for (const i in param) {
      // 遍历
      if (param.hasOwnProperty(i)) {
        const k = key
          ? key + (Array.isArray(param) ? '[' + i + ']' : '.' + i)
          : i;
        paramStr += '' + urlDonloadEncode(param[i], k, encode);
      }
    }
  }

  return paramStr;
}