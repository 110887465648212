// 判断是否是数组
export function isArray(val): boolean {
  return Object.prototype.toString.call(val) === '[object Array]';
}
// 判断是否是对象
export function isObject(val): boolean {
  return Object.prototype.toString.call(val) === '[object Object]';
}
// 判断是否是字符串
export function isString(val): boolean {
  return Object.prototype.toString.call(val) === '[object String]';
}
// 判断是否是ssr
export const isSSR = (function () {
  try {
    return !(typeof window !== 'undefined' && document !== undefined);
  } catch (e) {
    return true;
  }
})();
