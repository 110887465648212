import axios, { AxiosResponse } from 'axios';
import { Message } from '@arco-design/web-react';
// import { REACT_SERVER } from '@/api/service';
import { urlEncode, urlDonloadEncode } from '@/utils/mutils';
// console.log(REACT_SERVER)
// baseUrl
axios.defaults.baseURL =  process.env.REACT_APP_BASE_URL + '/api';
// 超时时间
axios.defaults.timeout = 1000 * 10 * 60;
// content type
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

// let loading: any;
// 请求拦截器
axios.interceptors.request.use(
  (config) => {
    // 赋予token
    const token = window.localStorage.getItem('token');
    // 将token添加进头部
    config.headers['Authorization'] = `Bearer ${token}`;
    // if (token) {
    // }
    // console.log(config)
    return config;
  },
  (error) => {
    return error;
  }
);
// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    const res = response;
    // 如果status不是200，则弹出错误提示
    if (response.status !== 200) {
      Message.error(res.data.message);
    } else {
      // 如果不是get请求
      if (res.config.method != 'get') {
        if (res.data.code) {
          // 如果code不是0000，则弹出错误提示
          if (res.data.code == '0000') {
          } else {
            Message.warning(res.data.message);
          }
        }
      } else {
        // 如果是get请求，且code不是0000，则弹出错误提示
        // console.log(res.data)
        if (res.data.code && res.data.code != '0000') {
          Message.warning(res.data.message);
        }
      }
    }
    return res;
  },
  (error) => {
    Message.error('网络错误，error');
    return Promise.reject(error);
  }
);
// 定义http接口
interface Http {
  // get方法
  get(url: string, params?: unknown): Promise<ResType.IresType>;
  // post方法
  post(url: string, params?: unknown): Promise<ResType.IresType>;
  // 上传文件
  upload(
    url: string,
    params?: unknown,
    fn?: () => void
  ): Promise<ResType.IresType>;
  //   下载文件
  downloadGet(url: string, params?: unknown): Promise<AxiosResponse>;
  // post方法
  downloadPost(url: string, params?: unknown): Promise<AxiosResponse>;
  // 删除方法
  delete(url: string, params?: unknown): Promise<ResType.IresType>;
  // 修改方法
  put(url: string, params?: unknown): Promise<ResType.IresType>;
  // 获取文字文本
  getText(url: string, params?: unknown): Promise<AxiosResponse>;
  // url编码
  urlEncCoded(url: string, params?: unknown): Promise<ResType.IresType>;
}

const http: Http = {
  // get方法
  get(url, params) {
    // 返回promise
    return new Promise((resolve, reject) => {
      axios
        .get(url, { params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  },
  // post方法
  post(url, params) {
    // 返回promise
    return new Promise((resolve, reject) => {
      axios
        .post(url, JSON.stringify(params))
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  },
  // url编码
  urlEncCoded(url, params) {
    return new Promise((resolve, reject) => {
      // 去除第一个&，避免第一行为空
      const _params = urlEncode(params).slice(1);
      // 发送请求
      axios
        .post(url, _params, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  },
  // 上传文件
  upload(url, params, fn) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, params, {
          // 设置请求头
          headers: { 'Content-Type': 'multipart/form-data' },
          // 上传进度回调
          onUploadProgress: fn,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  },
  // get下载
  downloadGet(url, params) {
    return new Promise((resolve, reject) => {
      // 使用 urlEncode 函数对 params 进行编码，但不包括开头的 &
      // 第三个参数设置为 false
      const _params = urlDonloadEncode(params, '', false); 
      // 将编码后的参数字符串附加到 URL 上
      const encodedUrl = `${url}?${_params}`;
      axios
        .get(encodedUrl, {
          // 设置响应类型为 blob
          responseType: 'blob', 
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, // 设置请求头
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // post下载
  downloadPost(url, params) {
    // 返回promise
    return new Promise((resolve, reject) => {
      axios
        .post(url, JSON.stringify(params), { responseType: 'blob' })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // 删除方法
  delete(url, params) {
    // 返回promise
    return new Promise((reslove, reject) => {
      axios
        .delete(`${url}/${params}`)
        .then((res) => {
          reslove(res.data);
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  },
  // 修改方法
  put(url, params) {
    // 返回promise
    return new Promise((reslove, reject) => {
      axios
        .put(url, params)
        .then((res) => {
          reslove(res.data);
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  },
  // 获取文字文本
  getText(url, params) {
    // 返回promise
    return new Promise((resolve, reject) => {
      axios
        .get(url, { params, responseType: 'text' })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  },
};

export default http;
