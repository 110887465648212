// 获取当前的tab
import { createSlice } from "@reduxjs/toolkit";

// 定义初始值类型
interface ITabsState {
    name:string
    key:string
}

// 标签栏的slice
const tabsSlice = createSlice({
    name: "tabs",
    // 初始值
    initialState: {
        tabList: [{name:'我的模板',key:'/gathering/my_template'}] as ITabsState[]// 存储tab列表
    },
    // reducers
    reducers: {
        // 新增tabs
        addTabs: (state, action) => {
            const {key,name}= action.payload
            // 检查是否存在这个
            const isItemExists = state.tabList.some(tab => tab.key === key)
            // 如果不存在，且tab少于10个
            if (!isItemExists && state.tabList.length < 10) {
                state.tabList.push({ name, key });
              } else {
                // 如果当前列表已经有了这个页面，那么就不新增
                return state;
              }
            // state.tabList.push(action.payload)
        },
        // 删除单个tabs
        deleteTabs: (state, action) => {
            const {key}= action.payload
            if(state.tabList.length==1){
                return state
            }else{
                // 删除对应的key
                state.tabList = state.tabList.filter(item => item.key!== key)
            }
        },
        // 删除左侧tabs
        removeLeftTabs: (state, action) => {
            // 首先找到被点击的tab的索引
            const index = state.tabList.findIndex(item => item.key === action.payload.key)
            // 创建一个数组，将索引左侧侧的tabs都删除,但是保留首页
            const newList = state.tabList.slice( index, state.tabList.length)
            state.tabList = newList
        },
        // 删除右侧tabs
        removeRightTabs: (state, action) => {
            // 首先找到被点击的tab的索引
            const index = state.tabList.findIndex(item => item.key === action.payload.key)
            // 创建一个数组，将索引左侧的tabs都删除
            const newList = state.tabList.slice(0,index+1) 
        
            state.tabList = newList
        },
        // 只保留首页的tabs
        clearTabs: (state) => {
            state.tabList = [{name:'数据对话',key:'/intelligent_analysis/data_conversation'}]  
        },
        // 关闭其他的tabs
        removeOther: (state, action) => {
            // 重新赋值
            state.tabList = [action.payload]  
        },
      
    }
})


export const { addTabs, deleteTabs,removeOther, removeLeftTabs, removeRightTabs, clearTabs } = tabsSlice.actions
export default tabsSlice.reducer